import { darkTheme, theme } from './theme/themeVariables'

const config = {
  darkMode: false,
  topMenu: false,
  rtl: false,
  theme,
  darkTheme,
  auth0: false,
}

export default config
