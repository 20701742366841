import { storeToRefs } from 'pinia'
import { useLocalAuth } from '@/pinia/AuthLocalStore'
import { useSessionAuth } from '@/pinia/AuthSessionStore'
import { useRefreshToken } from '@/config/api/useRefreshToken'

function useAuth() {
  const localAuth = useLocalAuth()
  const { user, refresh_token: localRefreshToken, selected_role, getRoles } = storeToRefs(localAuth)

  const { setUser, setRole } = localAuth
  const sessionAuth = useSessionAuth()
  const { getToken, hasSession } = storeToRefs(sessionAuth)
  const { setToken } = sessionAuth

  const { setRefreshTokens, getRefreshToken, isRemembered } = useRefreshToken()

  const { refresh_token: sessionRefreshToken, token: sessionToken }
    = storeToRefs(sessionAuth)

  return {
    setUser,
    setRole,
    getRoles,
    setToken,
    setRefreshTokens,
    getRefreshToken,
    getToken,
    isRemembered,
    hasSession,
    selected_role,
    user,
    localRefreshToken,
    sessionToken,
    sessionRefreshToken,
  }
}
export default useAuth
