import { useSessionAuth } from '@/pinia/AuthSessionStore'
import { useLocalAuth } from '@/pinia/AuthLocalStore'
import router from '@/routes/router.js'

export function resetApp() {
  const sessionAuth = useSessionAuth()
  const localAuth = useLocalAuth()
  const reset = () => {
    sessionAuth.$reset()
    localAuth.$reset()
  }
  return { reset }
}

export function useLogout() {
  const { reset } = resetApp()

  async function logout() {
    reset()
    if (router?.replace)
      await router.replace('/auth/login')
  }

  return { logout }
}
