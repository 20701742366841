import { useLocalAuth } from '@/pinia/AuthLocalStore'
import { useSessionAuth } from '@/pinia/AuthSessionStore'

export function useRefreshToken() {
  const { refresh_token: refreshToken, setRefreshToken } = useLocalAuth()
  const { refresh_token: refreshTokenSession, setRefreshTokenSession }
    = useSessionAuth()

  function isRemembered() {
    return Boolean(refreshToken)
  }

  function getRefreshToken() {
    if (isRemembered()) {
      return refreshToken
    }

    return refreshTokenSession
  }

  function setRefreshTokens(refreshToken, rememberMe = false) {
    setRefreshTokenSession(refreshToken)
    if (rememberMe) {
      setRefreshToken(refreshToken)
    }
  }

  return {
    isRemembered,
    setRefreshTokens,
    getRefreshToken,
  }
}
