import app from '@/config/configApp.js'
import router from '@/routes/router.js'
// Sentry.init({
//     app,
//     dsn: "https://2c849523034241b08347c4f88bd86fbb@sentry.vitrion.nl/16",
//     integrations: [
//         new BrowserTracing({
//             routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//             tracePropagationTargets: ["localhost", "my-site-url.com", /^\//],
//         }),
//     ],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });
app.use(router)
