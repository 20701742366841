import api from '@/config/api/Api'
import useAuth from '@/view/authentication/useAuth'
import ExpiredRefreshTokenError from '@/config/api/errors/ExpiredRefreshTokenError'
import { useSessionAuth } from '@/pinia/AuthSessionStore.ts'
import { useLocalAuth } from '@/pinia/AuthLocalStore.ts'
import router from '@/routes/router.js'

export async function setRefreshToken() {
  const { getRefreshToken, setToken, setRefreshTokens, setUser, isRemembered, setRole, selected_role }
        = useAuth()
  const config = {
    headers: {
      'content-type': 'application/json',
    },
  }

  let response
  try {
    response = await api.post('/auth/refresh', { refresh_token: getRefreshToken() }, config)
  }
  catch (refreshTokenException) {
    if ([401, 400].includes(refreshTokenException?.response?.status)) {
      const sessionAuth = useSessionAuth()
      const localAuth = useLocalAuth()
      sessionAuth.$reset()
      localAuth.$reset()
      if (router?.replace)
        await router.replace('/auth/login')
    }
    return Promise.reject(new ExpiredRefreshTokenError())
  }

  const { data } = response
  if (!data) {
    return Promise.reject(response)
  }
  const { user_roles } = data.user

  if (!user_roles?.length) {
    alert('Er is geen rol aan u toegewezen. Neem contact op met uw beheerder.')
    const sessionAuth = useSessionAuth()
    const localAuth = useLocalAuth()
    sessionAuth.$reset()
    localAuth.$reset()
    if (router?.replace)
      await router.replace('/auth/login')
    return
  }
  setToken(data.access_token)
  setRefreshTokens(data.refresh_token, isRemembered())
  setUser(data.user)

  console.log(user_roles)

  if (user_roles?.length === 1) {
    console.log('Setting role to: ', user_roles[0], 'because user_roles.length === 1')
    setRole(user_roles[0])
  }
  else {
    if (selected_role?.value?.id) {
      setRole(user_roles.find(user_role => user_role.id === selected_role.value.id))
    }
  }
}
