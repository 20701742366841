<script>
import { defineComponent } from 'vue'
import { ButtonStyledGroup } from './styled'

export default defineComponent({
  name: 'BtnGroup',
  components: {
    ButtonStyledGroup,
  },
})
</script>

<template>
  <ButtonStyledGroup><slot /></ButtonStyledGroup>
</template>
