import { defineStore } from 'pinia'
import type { User } from '@/view/pages/users/user.interface.ts'
import type { UserRole } from '@/view/pages/users/userRole.interface.ts'
import type { Tenant } from '@/view/pages/tenants/tenant.interface.ts'

interface AuthState {
  refresh_token: string | null
  user: User
  selected_role: UserRole | null
}

export const useLocalAuth = defineStore('auth_local', {
  state: (): AuthState => ({
    refresh_token: null,
    user: {} as User,
    selected_role: null,
  }),
  persist: {
    paths: ['refresh_token', 'selected_role'],
  },
  getters: {
    getUser: (state: AuthState): User => state.user,
    getRoles: (state: AuthState): UserRole[] | undefined => state.user.user_roles,
    isAdmin: (state: AuthState): boolean => state.user.user_roles?.some(role => role.role === 'admin') ?? false,
    isUser: (state: AuthState): boolean => state.selected_role?.role === 'user',
    isConsumer: (state: AuthState): boolean => state.selected_role?.tenant?.type === 'Consumer',
    isBusiness: (state: AuthState): boolean => state.selected_role?.tenant?.type === 'Business',
    hasExact: (state: AuthState): boolean => !!state.selected_role?.tenant?.hasExactOnline,
    hasOPP: (state: AuthState): boolean => !!state.selected_role?.tenant?.opp_payment?.id,
    hasEarnE: (state: AuthState): boolean => !!state.selected_role?.tenant?.hasEarnE,
  },
  actions: {
    setRole(role: UserRole): void {
      this.selected_role = role
    },
    updateRoleTenant(tenant: Tenant): void {
      if (this.selected_role) {
        this.selected_role.tenant = tenant
      }
    },
    setRefreshToken(token: string): void {
      this.refresh_token = token
    },
    setUser(user: User): void {
      this.user = user
    },
  },
})
