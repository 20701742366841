<script>
import { defineComponent, onMounted, ref } from 'vue'
import { CustomDropDown } from './styled'

export default defineComponent({
  name: 'CalendarButton',
  components: {
    CustomDropDown,
  },
  setup() {
    const isVisible = ref(false)
    const handleVisible = () => {
      isVisible.value = !isVisible.value
    }

    onMounted(() => {
      document.body.addEventListener('click', (e) => {
        if (e.target.closest('#dropdownParent')) {
          return false
        }
        else {
          isVisible.value = false
        }
      })
    })

    return {
      handleVisible,
      isVisible,
    }
  },
  data() {
    return {
      range: {
        start: new Date(2021, 0, 1),
        end: new Date(2021, 0, 5),
      },
    }
  },
})
</script>

<template>
  <CustomDropDown id="dropdownParent">
    <div
      v-if="isVisible"
      class="qWeb-range-calendar"
      placement="bottomRight"
      :title="$t('searchByCalendar')"
      action="click"
    >
      <v-calendar v-model="range" :columns="$screens({ default: 1, lg: 2 })" is-expanded is-range />
    </div>
    <Button size="small" type="white" @click="handleVisible">
      <FeatherIcons type="calendar" size="14" />
      <span>{{ $t('calendar') }}</span>
    </Button>
  </CustomDropDown>
</template>

<style>
.ant-page-header-heading {
  overflow: visible;
}
</style>
