<script>
import VueTypes from 'vue-types'
import { defineComponent } from 'vue'
import { CardFrame } from './style'

export default defineComponent({
  name: 'Cards',
  components: {
    CardFrame,
  },
  props: {
    title: VueTypes.oneOfType([VueTypes.string, VueTypes.object, VueTypes.node]),
    size: VueTypes.oneOf(['default', 'small']).def('default'),
    more: VueTypes.bool.def(false),
    bodyStyle: VueTypes.object,
    headStyle: VueTypes.object,
    headless: VueTypes.bool.def(false),
    border: VueTypes.bool.def(false),
    caption: VueTypes.string,
    bodypadding: VueTypes.string,
    moreText: VueTypes.bool.def(false),
  },
})
</script>

<template>
  <CardFrame
    v-if="!headless"
    :size="size"
    :title="title"
    :body-style="bodyStyle && bodyStyle"
    :head-style="headStyle && headStyle"
    :bordered="border"
    :bodypadding="bodypadding && bodypadding"
    :style="{ width: '100%' }"
  >
    <template #title>
      <slot name="title" />
    </template>
    <slot name="caption" />
    <template #extra>
      <Dropdown v-if="more" :action="['click']" placement="bottomRight">
        <template #overlay>
          <slot name="more" />
        </template>
        <router-link v-if="!moreText" to="#">
          <FeatherIcons type="more-horizontal" size="24" />
        </router-link>
        <router-link v-else to="#">
          {{ $t('more') }}
        </router-link>
      </Dropdown>
      <slot name="button" />
    </template>
    <slot />
  </CardFrame>

  <CardFrame
    v-else
    :bodypadding="bodypadding && bodypadding"
    :body-style="bodyStyle && bodyStyle"
    :size="size"
    :style="{ width: '100%' }"
    :bordered="border"
  >
    <Heading v-if="title" as="h4">
      {{ title }}
    </Heading>
    <template #title>
      <slot name="title" />
    </template>
    <p v-if="caption">
      {{ caption }}
    </p>
    <slot />
  </CardFrame>
</template>
