import { createStore } from 'vuex'
import themeLayout from './modules/themeLayout/actionCreator'
import headerSearchData from './modules/headerSearch/actionCreator'

export default createStore({
  modules: {
    themeLayout,
    headerSearchData,
  },
})
