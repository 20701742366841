<script setup>
import { ThemeProvider } from 'vue3-styled-components'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import nlNL from 'ant-design-vue/es/locale/nl_NL'
import { ConfigProvider } from 'ant-design-vue'
import { storeToRefs } from 'pinia'
import { theme } from './config/theme/themeVariables'
import { setRefreshToken } from '@/config/api/setRefreshToken'
import { useLocalAuth } from '@/pinia/AuthLocalStore'
import useAuth from '@/view/authentication/useAuth'

const { isRemembered, hasSession } = useAuth()
const globalLoading = ref(false)

const { state } = useStore()
const rtl = computed(() => state.themeLayout.rtlData)
const isLoading = computed(() => state.themeLayout.loading && globalLoading.value)
const darkMode = computed(() => state.themeLayout.data)
const topMenu = computed(() => state.themeLayout.topMenu)
const localAuth = useLocalAuth()
const { selected_role } = storeToRefs(localAuth)
if (!hasSession.value && !isRemembered()) {
  console.log('load1')
  globalLoading.value = false
}
console.log(hasSession, 'hasSes')
if (hasSession.value || isRemembered()) {
  console.log('load2')

  setRefreshToken().catch((e) => {
    console.log(e, 'error')
  }).finally(() => {
    globalLoading.value = false
  })
}
ConfigProvider.config({
  theme: {
    primaryColor: '#226FB0',
    borderRadiusBase: '#226FB0',
  },
})

watch(() => selected_role.value, (v) => {
  console.log(v, 'ROLE')
})
</script>

<template>
  <a-config-provider :locale="nlNL">
    <div v-if="isLoading" class="spin">
      <a-spin />
    </div>
    <ThemeProvider
      v-else
      :theme="{
        ...theme,
        rtl,
        topMenu,
        darkMode,
      }"
    >
      <Suspense>
        <template #default>
          <router-view />
        </template>
        <template #fallback>
          <div class="spin">
            <a-spin />
          </div>
        </template>
      </Suspense>
    </ThemeProvider>
  </a-config-provider>
</template>

<style lang="scss">
.hoverable:hover{
  cursor:pointer;
  opacity:0.5;
  transition: 0.3s;
}
.ant-btn-primary {
  background: #69BEAC;
  border-color: #69BEAC;
}
</style>
