<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ShareButton',
  components: {},
})
</script>

<template>
  <Popover placement="bottomLeft" action="click">
    <template #content>
      <a to="#">
        <FeatherIcons type="facebook" />
        <span>Facebook</span>
      </a>
      <a to="#">
        <FeatherIcons type="twitter" />
        <span>Twitter</span>
      </a>
      <a to="#">
        <FeatherIcons type="rss" />
        <span>Feed</span>
      </a>
      <a to="#">
        <FeatherIcons type="linkedin" />
        <span>Linkedin</span>
      </a>
      <a to="#">
        <FeatherIcons type="instagram" />
        <span>Instagram</span>
      </a>
    </template>
    <Button size="small" type="white">
      <FeatherIcons type="share-2" size="14" />
      <span>Share</span>
    </Button>
  </Popover>
</template>
