import { defineStore } from 'pinia'
import type { User } from '@/view/pages/users/user.interface.ts'

interface State {
  token: string | null
  refresh_token: string | null
  user: User
}

export const useSessionAuth = defineStore('auth_session', {
  state: (): State => ({
    token: null,
    refresh_token: null,
    user: {} as User,
  }),
  persist: {
    storage: window.sessionStorage,
  },
  getters: {
    getToken(state): string | null {
      return state.token
    },
    hasSession(state): boolean {
      return !!state.token
    },
    getUser(state): User {
      return state.user
    },
  },
  actions: {
    setToken(token: string): void {
      this.token = token
    },
    setUser(user: User): void {
      this.user = user
    },
    setRefreshTokenSession(token: string): void {
      this.refresh_token = token
    },
  },
})
