import app from '../../config/configApp'
import Cards from '../../components/cards/frame/CardsFrame.vue'
import Button from '../../components/buttons/Buttons.vue'
import BtnGroup from '../../components/buttons/ButtonsGroup.vue'
import FeatherIcons from '../../components/utilities/featherIcons.vue'
import CalendarButton from '../../components/buttons/CalendarButton.vue'
import ExportButton from '../../components/buttons/ExportButton.vue'
import ShareButton from '../../components/buttons/ShareButton.vue'
import PageHeaders from '../../components/pageHeaders/PageHeaders'
import Heading from '../../components/heading/Heading'
import AutoComplete from '../../components/autoComplete/autoComplete'
import Dropdown from '../../components/dropdown/Dropdown'
import Popover from '../../components/popup/Popup';

[
  Heading,
  Cards,
  {
    name: 'Button',
    ...Button,
  },
  {
    name: 'BtnGroup',
    ...BtnGroup,
  },
  CalendarButton,
  ExportButton,
  ShareButton,
  { name: 'PageHeader', ...PageHeaders },
  AutoComplete,
  Dropdown,
  Popover,
  FeatherIcons,
].map((c) => {
  app.component(`${c.name}`, c)
})
