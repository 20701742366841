<script>
import VueTypes from 'vue-types'
import { defineComponent } from 'vue'
import { Content } from './dropdown-style'

export default defineComponent({
  name: 'Dropdown',
  components: {
    Content,
  },
  props: {
    placement: VueTypes.string.def('bottomRight'),
    title: VueTypes.string,
    action: VueTypes.array.def(['hover']),
    class: VueTypes.string.def('Propertylinqs-dropdown'),
  },
  data() {
    return {
      className: this.class,
    }
  },
})
</script>

<template>
  <a-dropdown :overlay-class-name="className" :placement="placement" :title="title" :trigger="action">
    <template #overlay>
      <Content>
        <slot name="overlay">
          <a to="#">
            <span>{{ $t('exportToCsv') }}</span>
          </a>
          <a to="#">
            <span>{{ $t('exportToXml') }}</span>
          </a>
          <a to="#">
            <span>{{ $t('exportToDrive') }}</span>
          </a>
        </slot>
      </Content>
    </template>
    <slot />
  </a-dropdown>
</template>
