<script>
import VueTypes from 'vue-types'
import { computed, defineComponent, ref, toRefs } from 'vue'
import { useStore } from 'vuex'
import { AutoCompleteStyled } from './style'

export default defineComponent({
  name: 'AutoComplete',
  components: {
    AutoCompleteStyled,
  },
  props: {
    customComponent: VueTypes.bool.def(false),
    patterns: VueTypes.bool.def(false),
    patternButtons: VueTypes.bool.def(false),
    width: VueTypes.string.def('350px'),
    dataSource: VueTypes.array,
    placeholder: VueTypes.string.def('Input here'),
  },
  setup(props, { emit }) {
    const { dataSource } = toRefs(props)
    const { state } = useStore()
    const value = ref('')
    const myData = ref(dataSource.value)
    const rtl = computed(() => state.themeLayout.rtlData)

    const onSearching = (searchText) => {
      value.value = searchText
      const data = dataSource.value.filter(item => item.title.toUpperCase().startsWith(searchText.toUpperCase()))
      emit('onSearch', searchText)
      return (myData.value = !searchText ? dataSource.value : data)
    }

    const onSelect = () => {}

    return {
      value,
      myData,
      rtl,
      onSearching,
      onSelect,
    }
  },
})
</script>

<template>
  <AutoCompleteStyled v-if="customComponent" :style="{ width }" @select="onSelect" @search="onSearching">
    <template v-if="myData.length" #options>
      <a-select-option v-for="item in myData" :key="item.title">
        {{ item.title }}
      </a-select-option>
    </template>
    <template v-else #options>
      <a-select-option key="Data not found!">
        {{ $t('dataNotFound') }}
      </a-select-option>
    </template>
    <slot />
  </AutoCompleteStyled>
  <AutoCompleteStyled
    v-else-if="patterns"
    v-model:value="value"
    class="certain-category-search"
    dropdown-class-name="certain-category-search-dropdown"
    :dropdown-match-select-width="false"
    :dropdown-style="{ width: 300 }"
    :style="{ width }"
    :placeholder="placeholder"
    @search="onSearching"
  >
    <template v-if="myData.length" #options>
      <a-select-option v-for="item in myData" :key="item.title">
        {{ item.title }}
      </a-select-option>
    </template>
    <template v-else #options>
      <a-select-option key="Data not found!">
        {{ $t('dataNotFound') }}
      </a-select-option>
    </template>
    <a-input>
      <template #suffix>
        <Button
          v-if="patternButtons"
          class="search-btn"
          :style="{ [rtl ? 'marginLeft' : 'marginRight']: -20 }"
          type="primary"
        >
          <FeatherIcons type="search" />
        </Button><FeatherIcons v-else type="search" />
      </template>
    </a-input>
  </AutoCompleteStyled>

  <AutoCompleteStyled
    v-else
    :style="{ width }"
    :placeholder="placeholder"
    :value="value"
    @select="onSelect"
    @search="onSearching"
  >
    <template v-if="myData.length" #options>
      <a-select-option v-for="item in myData" :key="item.title">
        {{ item.title }}
      </a-select-option>
    </template>
    <template v-else #options>
      <a-select-option key="Data not found!">
        {{ $t('dataNotFound') }}
      </a-select-option>
    </template>
  </AutoCompleteStyled>
</template>
